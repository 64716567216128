<template>
  <div class="main">
    <div class="left">
      <question1 ref="question1Ref"
                 style="width: 100%;"
                 :form="form" />
    </div>
    <div class="right">
      <question2 ref="question2Ref"
                 style="width: 100%;"
                 :form="form" />
    </div>

    <div class="bottom"
         v-if="form[0]&&form[0].dup_index==0.5">
      <div>
        <el-radio-group v-model="has_repeat">
          <el-radio :label="1">题目重复</el-radio>
          <el-radio :label="0">题目不重复</el-radio>

        </el-radio-group>
      </div>
      <div class="btn">
        <el-button type=""
                   @click="cancelSubmit">取消</el-button>
        <el-button type="primary"
                   @click="submit">提交</el-button>
      </div>
    </div>
    <div class="bottom"
         v-else>
      <div>
        {{  form[0]&&form[0].dup_index==1?"审核结果：题目重复":"审核结果：题目不重复" }}
      </div>
    </div>
  </div>
</template>

<script>
import question1 from './question1.vue'
import question2 from './question2.vue'
export default {
  data () {
    return {
      dialogVisible: false,
      innerVisible: false,
      reason: '',
      form: [],
      has_repeat: null
    }
  },
  mounted () {

    this.form.push(JSON.parse(this.$route.query.qusInfo))
    console.log('JSON.parse(this.$route.query.qusInfo)', JSON.parse(this.$route.query.qusInfo));


  },
  components: {
    question2, question1
  },
  methods: {
    init () {
      this.$question1Ref.form = this.form;
      this.$question2Ref.form = this.form;
    },
    handleClose () {
      document.getElementsByClassName('view_warp')[0].style.background = ''
      document.getElementsByClassName('view_warp')[0].style.padding = '15px'
      this.innerVisible = false
      this.reason = ''
      this.dialogVisible = false
    },
    cancelSubmit () {
      this.$router.push('/examinationEntry/record')
    },
    submit () {
      if (this.has_repeat == null) {
        this.$message.error('请选择题目重复或不重复')
      }
      this.$http({
        url: '/api/v1/question/dup_confirm',
        method: 'post',
        data: {
          id: JSON.parse(this.$route.query.qusInfo).id,
          has_repeat: this.has_repeat

        }
      }).then(res => {
        if (res.status == 200) {
          this.$message.success('审核成功')
          this.$router.push('/examinationEntry/record')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 2px;
  background: #fff;
  .left,
  .right {
    display: flex;
    // height: 838px;
    min-width: 720px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    border-right: 1px solid var(--neutral-color-eeeeee, #eee);
    border-left: 1px solid var(--neutral-color-eeeeee, #eee);
  }
  .bottom {
    display: flex;
    justify-content: center;
    min-width: 720px;
    padding: 10px 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    height: 100px;
    flex: 1 0 0;
    border-top: 1px solid var(--neutral-color-eeeeee, #eee);
  }
}
::v-deep .el-button {
  width: 60px;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>